<template>
	<div class="bg-dark" id="layoutAuthentication">
		<div id="layoutAuthentication_content">
			<main>
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-5">
							<div class="card shadow-lg border-0 rounded-lg mt-5">
								<div class="card-header">
									<h3 class="text-center font-weight-light my-4" v-html="title"></h3></div>
								<div class="card-body">
									<form @submit="login">
										<div class="form-floating mb-3">
											<input class="form-control"
												   id="username"
												   type="text"
												   placeholder="Username"
												   v-model="username"
												   required
											/>
											<label for="username">Username</label>
										</div>
										<div class="form-floating mb-3">
											<input class="form-control"
												   id="password"
												   type="password"
												   placeholder="Password"
												   v-model="password"
												   required
											/>
											<label for="password">Password</label>
										</div>

										<!--										<div class="d-flex align-items-center justify-content-between mt-4 mb-0">-->
										<!--											<a class="small" href="password.html">Forgot Password?</a>-->
										<!--										</div>-->

										<div class="d-grid gap-2">
											<button type="submit" class="btn btn-primary btn-lg">Login</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
import axios           from "axios";
import {toastr}        from '../utils/toastr';
import * as siteConfig from '../../site.config';

export default {
	name: "Login",
	data() {
		return {
			title:    siteConfig.title,
			username: null,
			password: null
		};
	},
	methods: {
		login: function (event) {
			event.preventDefault();

			const data = JSON.stringify({
				"identifier": this.username,
				"password":   this.password
			});

			const config = {
				method:  'post',
				url:     siteConfig.urlServer + '/auth/local',
				headers: {
					'Content-Type': 'application/json'
				},
				data:    data
			};

			axios(config)
				.then(response => {
					this.$store.commit('login', {
						jwt:  response.data.jwt,
						user: response.data.user
					});
				})
				.catch(() => {
					toastr.error('Credenziali non valide');
				});
		}
	}
}
</script>

<style scoped>

</style>
