import axios           from "axios";
import store           from '../store';
import * as siteConfig from "../../site.config";
import {serialize}     from "./functions";

const doPing = () => {
    const config = {
        method:  'get',
        url:     siteConfig.urlServer + '/ping',
        headers: {}
    };

    return axios(config);
};

const doCreate = (entityName, data) => {
    const config = {
        method:  'post',
        url:     siteConfig.urlServer + '/' + entityName,
        headers: {
            'Authorization': 'Bearer ' + store.getters.jwt,
            'Content-Type':  'application/json'
        },
        data:    JSON.stringify(data)
    };

    return axios(config);
};

const doFind = (entityName, query) => {
    if (query) {
        query = '?' + serialize(query);
    } else {
        query = '';
    }

    const config = {
        method:  'get',
        url:     siteConfig.urlServer + '/' + entityName + query,
        headers: {
            'Authorization': 'Bearer ' + store.getters.jwt
        }
    };

    return axios(config);
};

const doFindOne = (entityName, id) => {
    const config = {
        method:  'get',
        url:     siteConfig.urlServer + '/' + entityName + '/' + id,
        headers: {
            'Authorization': 'Bearer ' + store.getters.jwt
        }
    };

    return axios(config);
};

const doCount = (entityName) => {
    const config = {
        method:  'get',
        url:     siteConfig.urlServer + '/' + entityName + '/count',
        headers: {
            'Authorization': 'Bearer ' + store.getters.jwt
        }
    };

    return axios(config);
};

const doUpdate = (entityName, id, data) => {
    const config = {
        method:  'put',
        url:     siteConfig.urlServer + '/' + entityName + '/' + id,
        headers: {
            'Authorization': 'Bearer ' + store.getters.jwt,
            'Content-Type':  'application/json'
        },
        data:    JSON.stringify(data)
    };

    return axios(config);
};

const doDelete = (entityName, id) => {
    const config = {
        method:  'delete',
        url:     siteConfig.urlServer + '/' + entityName + '/' + id,
        headers: {
            'Authorization': 'Bearer ' + store.getters.jwt
        }
    };

    return axios(config);
};

export {
    doPing,
    doCreate,
    doFind,
    doFindOne,
    doCount,
    doUpdate,
    doDelete
};
