<template>
	<div id="layoutSidenav_nav">
		<nav class="sb-sidenav accordion sb-sidenav-dark">
			<div class="sb-sidenav-menu">
				<div class="nav">
					<div v-for="(menu, index) in menus"
						 :key="'menu' + index"
					>
						<div class="sb-sidenav-menu-heading" v-html="menu.label"></div>

						<router-link v-for="(link, index2) in menu.links"
									 :key="'link' + index2"
									 class="nav-link"
									 :to="link.to"
						>
							<div v-if="link.icon"
								 class="sb-nav-link-icon"
							>
								<i :class="link.icon"></i>
							</div>

							<span v-html="link.label"></span>
						</router-link>
					</div>
				</div>
			</div>

			<div class="sb-sidenav-footer">
				<div class="small">Loggato come:</div>
				{{ username }}
			</div>
		</nav>
	</div>
</template>

<script>
import {doCount}   from "../utils/client";
import Armors      from "../views/Armors";
import Fabrics     from "../views/Fabrics";
import FabricTypes from "../views/FabricTypes";
import Positions   from "../views/Positions";
import Suppliers   from "../views/Suppliers";
import Washings    from "../views/Washings";

export default {
	name: "Sidebar",
	data(){
		return {
			menus: [
				{
					label: 'Menù',
					links: [
						{key: Fabrics.data().ENTITY_NAME, label: 'Tessuti', icon: 'fas fa-layer-group', to: '/'}
					]
				},
				{
					label: 'Posizioni',
					links: [
						{key: Positions.data().ENTITY_NAME, label: 'Posizioni', icon: 'fas fa-map-marker-alt', to: '/posizioni'},
					]
				},
				{
					label: 'Dati',
					links: [
						{key: Armors.data().ENTITY_NAME, label: 'Armature', icon: 'fas fa-shield-alt', to: '/armature'},
						{key: Suppliers.data().ENTITY_NAME, label: 'Fornitori', icon: 'fas fa-parachute-box', to: '/fornitori'},
						{key: Washings.data().ENTITY_NAME, label: 'Lavaggi', icon: 'fas fa-soap', to: '/lavaggi'},
						{key: FabricTypes.data().ENTITY_NAME, label: 'Tipi tessuti', icon: 'fas fa-toilet-paper', to: '/tipi-tessuti'}
					]
				}
			]
		};
	},
	computed: {
		username: function () {
			return this.$store.getters.user.username;
		},
		appLoading: function () {
			return this.$store.getters.isLoading;
		}
	},
	watch: {
		appLoading: function (newData, oldData) {
			if (!newData && oldData) {
				this.loadCounts();
			}
		}
	},
	mounted() {
		this.loadCounts();
	},
	methods:{
		loadCounts: function () {
			this.menus.map(m => {
				m.links.map(l => {
					doCount(l.key).then(r => {
						l.label += ` (${r.data})`;
					});
				});
			});
		}
	}
}
</script>

<style scoped>

</style>
