<template>
	<div>
		<h1 class="mt-4">
			<i v-if="ICON" :class="ICON"></i>
			{{ TITLE }}
		</h1>

		<div class="mb-3">
			<button class="btn btn-success"
					data-bs-toggle="modal"
					:data-bs-target="'#' + ENTITY_NAME + 'Modal'"
					@click="setNew"
			>
				<i class="fas fa-plus"></i>
				Aggiungi
			</button>
		</div>

		<table v-show="!loading" class="table table-striped table-hover table-bordered">
			<thead>
			<tr>
				<th scope="col">ID</th>
				<th scope="col">Nome</th>
				<th scope="col">Categoria</th>
				<th scope="col">Immagine</th>
				<th scope="col">Operazioni</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="(item, index) in items"
				:key="ENTITY_NAME + index"
			>
				<th scope="row" class="align-middle">{{ item.id }}</th>
				<td class="align-middle">{{ item.name }}</td>
				<td class="align-middle">{{ item.category }}</td>
				<td class="align-middle text-center">
					<img v-if="item.image" :src="item.image" alt="" class="washing-image">
				</td>
				<td class="text-center align-middle">
					<div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
						<button class="btn btn-info"
								data-bs-toggle="modal"
								:data-bs-target="'#' + ENTITY_NAME + 'ModalInfo'"
								@click="setShowing(item)"
								v-if="item.description"
						>
							<i class="fas fa-info-circle"></i>
						</button>
						<button class="btn btn-primary"
								data-bs-toggle="modal"
								:data-bs-target="'#' + ENTITY_NAME + 'Modal'"
								@click="setEdit(item)"
						>
							<i class="fas fa-pencil-alt"></i>
						</button>
						<button class="btn btn-danger"
								data-bs-toggle="modal"
								:data-bs-target="'#' + ENTITY_NAME + 'ModalDelete'"
								@click="setEdit(item)"
								:disabled="!item.canBeDeleted"
						>
							<i class="fas fa-trash-alt"></i>
						</button>
					</div>
				</td>
			</tr>
			<tr v-if="items.length === 0">
				<td colspan="3" class="text-center table-info">{{ LABEL_EMPTY }}</td>
			</tr>
			</tbody>
		</table>
		<Loader v-show="loading"/>

		<div class="modal fade" :id="ENTITY_NAME + 'Modal'" tabindex="-1" :aria-labelledby="ENTITY_NAME + 'ModalLabel'" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" :id="ENTITY_NAME + 'ModalLabel'">
							{{ id ? LABEL_EDIT : LABEL_NEW }}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<form @submit="save">
						<div class="modal-body">
							<div class="form-floating mb-3">
								<input class="form-control"
									   :id="'name' + ENTITY_NAME"
									   type="text"
									   placeholder="Nome *"
									   v-model.trim="name"
									   required
								/>
								<label :for="'name' + ENTITY_NAME">Nome *</label>
							</div>

							<div class="form-floating mb-3">
								<textarea class="form-control"
										  placeholder="Descrizione"
										  :id="'description' + ENTITY_NAME"
										  style="height: 100px"
										  v-model.trim="description"
								></textarea>
								<label :for="'description' + ENTITY_NAME">Descrizione</label>
							</div>

							<div class="form-floating mb-3">
								<input class="form-control"
									   :id="'category' + ENTITY_NAME"
									   type="text"
									   placeholder="Categoria"
									   v-model.trim="category"
									   :list="'categoryDataList' + ENTITY_NAME"
									   autocomplete="off"
								/>
								<datalist :id="'categoryDataList' + ENTITY_NAME">
									<option v-for="category in categories"
											:key="'categoryDataList' + ENTITY_NAME + category"
											:value="category"
									/>
								</datalist>
								<label :for="'category' + ENTITY_NAME">Categoria</label>
							</div>

							<div class="mb-3">
								<label :for="'image' + ENTITY_NAME"
									   class="form-label"
								>
									Immagine
								</label>
								<input class="form-control"
									   type="file"
									   :id="'image' + ENTITY_NAME"
									   @change="getImageData"
								>
							</div>

							<div v-if="image">
								<img :src="image" alt="" class="washing-image">
								<a href="#"
								   class="text-danger ms-2"
								   @click="removeImage"
								>
									<i class="fas fa-times"></i>
									Elimina immagine
								</a>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button"
									class="btn btn-secondary"
									data-bs-dismiss="modal"
									ref="closeModal"
							>
								Annulla
							</button>
							<button type="submit" class="btn btn-success">
								Salva
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" :id="ENTITY_NAME + 'ModalInfo'" tabindex="-1" :aria-labelledby="ENTITY_NAME + 'ModalInfoLabel'" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="showing" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" :id="ENTITY_NAME + 'ModalInfoLabel'">
							{{ showing.name }}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						{{ showing.description }}
					</div>
					<div class="modal-footer">
						<button type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
						>
							Chiudi
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" :id="ENTITY_NAME + 'ModalDelete'" tabindex="-1" :aria-labelledby="ENTITY_NAME + 'ModalDeleteLabel'" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" :id="ENTITY_NAME + 'ModalDeleteLabel'">
							{{ LABEL_DELETE }}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						Eliminare {{ description }}?
					</div>
					<div class="modal-footer">
						<button type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
								ref="closeModalDelete"
						>
							Annulla
						</button>
						<button type="button"
								class="btn btn-danger"
								@click="deleteItem"
						>
							<i class="fas fa-trash-alt"></i>
							Elimina
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader                                 from "../components/Loader";
import {doCreate, doDelete, doFind, doUpdate} from "../utils/client";
import {unique}                               from "../utils/functions";
import {toastr}                               from '../utils/toastr';

export default {
	name:       "Washings",
	components: {Loader},
	data() {
		return {
			ENTITY_NAME:  'washings',
			ICON:         'fas fa-soap',
			TITLE:        'Lavaggi',
			LABEL_NEW:    'Nuovo lavaggio',
			LABEL_EDIT:   'Modifica lavaggio',
			LABEL_DELETE: 'Elimina lavaggio',
			LABEL_EMPTY:  'Nessun lavaggio trovato',

			loading:     true,
			items:       [],
			name:        null,
			description: null,
			category:    null,
			image:       null,
			id:          null,

			showing:    null,
			categories: []
		};
	},
	computed: {
		appLoading: function () {
			return this.$store.getters.isLoading;
		}
	},
	watch:    {
		appLoading: function (newData, oldData) {
			if (!newData && oldData) {
				this.load();
			}
		}
	},
	mounted() {
		this.load();
	},
	methods: {
		load:         function () {
			doFind(this.ENTITY_NAME)
				.then(response => {
					this.items = response.data.map(async r => {
						// TODO
						r.canBeDeleted = true;
						return r;
					});

					Promise.all(this.items).then(results => {
						this.items      = results;
						this.categories = unique(this.items.map(i => i.category)).sort();
						this.sortItems();
						this.loading = false;
					});
				})
				.catch(error => {
					console.error(error);
					if (!this.appLoading) {
						toastr.error('Si è verificato un errore');
					}
					this.loading = false;
				});
		},
		setNew:       function () {
			this.id          = null;
			this.name        = null;
			this.description = null;
			this.category    = null;
			this.image       = null;
		},
		setShowing:   function (item) {
			this.showing = item;
		},
		setEdit:      function (item) {
			this.id          = item.id;
			this.name        = item.name;
			this.description = item.description;
			this.category    = item.category;
			this.image       = item.image;
		},
		sortItems:    function () {
			this.items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
		},
		save:         function (event) {
			event.preventDefault();

			if (this.id === null) {
				doCreate(this.ENTITY_NAME, {
					"name":        this.name,
					"description": this.description,
					"category":    this.category,
					"image":       this.image
				})
					.then(response => {
						response.data.canBeDeleted = true;

						this.items.push(response.data);
						this.sortItems();

						this.$refs.closeModal.click();
						this.setNew();
					})
					.catch(error => {
						console.error(error);
						toastr.error('Si è verificato un errore');
					});
			} else {
				const data = {
					"name":        this.name,
					"description": this.description,
					"category":    this.category,
					"image":       this.image
				};
				const dataKeys = Object.keys(data);
				for (let i = 0; i < dataKeys.length; i++) {
					if (data[dataKeys[i]] !== '') {
						continue;
					}
					data[dataKeys[i]] = null;
				}

				doUpdate(this.ENTITY_NAME, this.id, data)
					.then(response => {
						const i = this.items.findIndex(elm => elm.id === this.id);

						response.data.canBeDeleted = this.items[i].canBeDeleted;

						this.items[i] = response.data;
						this.sortItems();

						this.$refs.closeModal.click();
						this.setNew();
					})
					.catch(error => {
						console.error(error);
						toastr.error('Si è verificato un errore');
					});
			}
		},
		deleteItem:   function (event) {
			event.preventDefault();

			doDelete(this.ENTITY_NAME, this.id)
				.then(() => {
					const i = this.items.findIndex(elm => elm.id === this.id);
					this.items.splice(i, 1);
					this.sortItems();

					this.$refs.closeModalDelete.click();

					this.id          = null;
					this.description = null;
				})
				.catch(error => {
					console.error(error);
					toastr.error('Si è verificato un errore');
				});
		},
		getImageData: function (event) {
			event.preventDefault();

			const reader  = new FileReader();
			reader.onload = event2 => {
				this.image = event2.target.result;
			};
			reader.readAsDataURL(event.target.files[0]);
		},
		removeImage:  function (event) {
			event.preventDefault();
			this.image = null;
		}
	}
}
</script>

<style scoped lang="scss">
.washing-image {
	max-height: 50px;
}

.btn-danger {
	&:disabled {
		background-color: var(--bs-gray);
		border-color:     var(--bs-gray);
	}
}
</style>
