<template>
	<div class="fs-4 text-secondary text-center">
		<i class="fas fa-circle-notch fa-spin"></i> Caricamento...
	</div>
</template>

<script>
export default {
	name: "Loader"
}
</script>

<style scoped>

</style>
