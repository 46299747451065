import Vue             from 'vue';
import VueRouter       from 'vue-router';
import store           from '../store';
import Armors          from "../views/Armors";
import Fabrics         from "../views/Fabrics";
import FabricTypes     from "../views/FabricTypes";
import Login           from "../views/Login";
import * as siteConfig from '../../site.config';
import Positions       from "../views/Positions";
import Search          from "../views/Search";
import Suppliers       from "../views/Suppliers";
import Washings        from "../views/Washings";

Vue.use(VueRouter);

const routes = [
    {
        path:      '/login',
        name:      "Login",
        component: Login,
        meta:      {guest: true}
    },
    {
        path:      '/',
        name:      'Tessuti',
        component: Fabrics,
        meta:      {requiresAuth: true}
    },
    {
        path:      '/armature',
        name:      'Armature',
        component: Armors,
        meta:      {requiresAuth: true}
    },
    {
        path:      '/tipi-tessuti',
        name:      'Tipi tessuti',
        component: FabricTypes,
        meta:      {requiresAuth: true}
    },
    {
        path:      '/fornitori',
        name:      'Fornitori',
        component: Suppliers,
        meta:      {requiresAuth: true}
    },
    {
        path:      '/lavaggi',
        name:      'Lavaggi',
        component: Washings,
        meta:      {requiresAuth: true}
    },
    {
        path:      '/posizioni',
        name:      'Posizioni',
        component: Positions,
        meta:      {requiresAuth: true}
    },
    {
        path:      '/search/:string',
        name:      'Ricerca',
        component: Search,
        meta:      {requiresAuth: true}
    }
];

const router = new VueRouter({
    routes
});

router.beforeEach(function (to, from, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/login');
        return;
    } else if (to.meta.guest && store.getters.isAuthenticated) {
        next('/');
        return;
    }

    document.title = [to.name, siteConfig.title].join(' | ');

    next();
});

export default router;
