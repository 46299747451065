<template>
	<div class="bg-dark vh-100 d-flex">
		<div class="loader text-center mx-auto font-monospace fs-2 text-white py-4 align-self-center">
			<b v-html="label"></b>
		</div>
	</div>
</template>

<script>
export default {
	name:  "BigLoader",
	props: {
		label: {
			required: false,
			default:  'Caricamento...'
		}
	}
}
</script>

<style scoped lang="scss">
@property --a {
	initial-value: 0deg;
	inherits:      false;
	syntax:        '<angle>';
}

@property --h {
	initial-value: 0;
	inherits:      false;
	syntax:        '<number>';
}

.loader {
	border-style:      solid;
	border-width:      1vmin;
	--charge:          hsl(var(--h, 0), 80%, 50%);
	-o-border-image:   conic-gradient(var(--charge) var(--a), transparent calc(var(--a) + 0.5deg)) 30;
	border-image:      conic-gradient(var(--charge) var(--a), transparent calc(var(--a) + 0.5deg)) 30;
	-webkit-animation: load 1.5s infinite ease-in-out;
	animation:         load 1.5s infinite ease-in-out;
	width:             75%;

	@media (min-width: 767.98px) {
		width: 50%;
	}

	@media (min-width: 991.98px) {
		width: 30%;
	}
}

@-webkit-keyframes load {
	0%, 10% {
		--a: 0deg;
		--h: 0;
	}
	100% {
		--a: 360deg;
		--h: 100;
	}
}

@keyframes load {
	0%, 10% {
		--a: 0deg;
		--h: 0;
	}
	100% {
		--a: 360deg;
		--h: 100;
	}
}

</style>
