<template>
	<div>
		<h1 class="mt-4">
			<i v-if="ICON" :class="ICON"></i>
			{{ TITLE }}
		</h1>

		<div class="mb-3">
			<button class="btn btn-success"
					data-bs-toggle="modal"
					:data-bs-target="'#' + ENTITY_NAME + 'Modal'"
					@click="setNew(null)"
					:ref="'open' + ENTITY_NAME + 'Modal'"
			>
				<i class="fas fa-plus"></i>
				Aggiungi gruppo
			</button>

			<button class="btn btn-danger d-none"
					data-bs-toggle="modal"
					:data-bs-target="'#' + ENTITY_NAME + 'ModalDelete'"
					:ref="'open' + ENTITY_NAME + 'ModalDelete'"
			>
				Elimina gruppo
			</button>
		</div>

		<div v-show="!loading">
			<div v-if="items.length > 0">
				<div class="card mb-3 shadow-sm"
					 v-for="item in items"
					 :key="ENTITY_NAME + item.id"
				>
					<div class="card-header d-flex">
						<b class="me-auto">{{ item.name }}</b>
						<div class="btn-group" role="group" aria-label="Basic example">
							<button class="btn btn-outline-success btn-sm"
									data-bs-toggle="modal"
									:data-bs-target="'#' + ENTITY_NAME + 'Modal'"
									@click="setNew(item.id)"
							>
								<i class="fas fa-plus"></i>
							</button>
							<button class="btn btn-outline-primary btn-sm"
									data-bs-toggle="modal"
									:data-bs-target="'#' + ENTITY_NAME + 'Modal'"
									@click="setEdit(item)"
							>
								<i class="fas fa-pencil-alt"></i>
							</button>
							<button class="btn btn-outline-danger btn-sm"
									:disabled="!canBeDeleted(item)"
									@click="openModalDelete($event, item)"
							>
								<i class="fas fa-trash-alt"></i>
							</button>
						</div>
					</div>
					<div class="card-body p-0">
						<div v-if="item.childrens.length > 0">
							<PositionRow v-for="position in item.childrens"
										 :key="ENTITY_NAME + position.id"
										 :position="position"
										 :on-click-add="openModal"
										 :on-click-edit="openModalEdit"
										 :on-click-delete="openModalDelete"
										 :ref="'sub' + ENTITY_NAME + position.id"
							/>
						</div>
						<div v-else class="bg-info p-2 border text-center">
							Nessuna posizione disponibile per il gruppo
						</div>
					</div>
				</div>
			</div>
			<div v-else class="alert alert-info text-center" role="alert">
				{{ LABEL_EMPTY }}
			</div>
		</div>
		<Loader v-show="loading"/>

		<div class="modal fade" :id="ENTITY_NAME + 'Modal'" tabindex="-1" :aria-labelledby="ENTITY_NAME + 'ModalLabel'" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" :id="ENTITY_NAME + 'ModalLabel'">
							{{ id ? LABEL_EDIT : LABEL_NEW }}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<form @submit="save">
						<div class="modal-body">
							<div class="form-floating mb-3">
								<input class="form-control"
									   :id="'name' + ENTITY_NAME"
									   type="text"
									   placeholder="Nome"
									   v-model.trim="name"
									   required
								/>
								<label :for="'name' + ENTITY_NAME">Nome</label>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button"
									class="btn btn-secondary"
									data-bs-dismiss="modal"
									ref="closeModal"
							>
								Annulla
							</button>
							<button type="submit" class="btn btn-success">
								Salva
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" :id="ENTITY_NAME + 'ModalDelete'" tabindex="-1" :aria-labelledby="ENTITY_NAME + 'ModalDeleteLabel'" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" :id="ENTITY_NAME + 'ModalDeleteLabel'">
							{{ LABEL_DELETE }}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						Eliminare {{ name }}?
					</div>
					<div class="modal-footer">
						<button type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
								ref="closeModalDelete"
						>
							Annulla
						</button>
						<button type="button"
								class="btn btn-danger"
								@click="deleteItem"
						>
							<i class="fas fa-trash-alt"></i>
							Elimina
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader                                 from "../components/Loader";
import PositionRow                            from "../components/PositionRow";
import {doCreate, doDelete, doFind, doUpdate} from "../utils/client";
import {toastr}                               from "../utils/toastr";
import Fabrics                                from "./Fabrics";

export default {
	name:       "Positions",
	components: {PositionRow, Loader},
	data() {
		return {
			ENTITY_NAME:  'positions',
			ICON:         'fas fa-map-marker-alt',
			TITLE:        'Posizioni',
			LABEL_EMPTY:  'Nessuna posizione trovata',
			LABEL_NEW:    'Nuova posizione',
			LABEL_EDIT:   'Modifica posizione',
			LABEL_DELETE: 'Elimina posizione',

			loading:      true,
			items:        [],
			name:         null,
			id:           null,
			parentId:     null,
			subOperation: false
		};
	},
	computed: {
		appLoading: function () {
			return this.$store.getters.isLoading;
		}
	},
	watch:    {
		appLoading: function (newData, oldData) {
			if (!newData && oldData) {
				this.load();
			}
		}
	},
	mounted() {
		this.load();
	},
	methods: {
		load:   function () {
			doFind(this.ENTITY_NAME)
				.then(response => {
					try {
						response.data = response.data.map(async r => {
							r.canBeDeleted = (await doFind(Fabrics.data().ENTITY_NAME, {position: r.id})).data.length <= 0;
							return r;
						});

						Promise.all(response.data).then(results => {
							this.items = this.listToTree(results);
							this.sortItems();
							this.loading = false;
						});
					} catch (e) {
						console.error(e);
						this.loading = false;
					}
				})
				.catch(error => {
					console.error(error);
					if (!this.appLoading) {
						toastr.error('Si è verificato un errore');
					}
					this.loading = false;
				});
		},
		listToTree:      function (list) {
			let map = {}, node, roots = [];

			for (let i = 0; i < list.length; i += 1) {
				map[list[i].id]   = i; // initialize the map
				list[i].childrens = []; // initialize the children
			}

			for (let i = 0; i < list.length; i += 1) {
				node = list[i];
				if (node.position !== null && list[map[node.position.id]]) {
					list[map[node.position.id]].childrens.push(node);
				} else {
					roots.push(node);
				}
			}

			return roots;
		},
		setNew:          function (parentId = null) {
			if (this.subOperation) {
				return;
			}

			this.id       = null;
			this.name     = null;
			this.parentId = parentId;
		},
		setEdit:         function (item) {
			this.id   = item.id;
			this.name = item.name;
		},
		sortItems:       function () {
			this.items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
		},
		save:            function (event) {
			event.preventDefault();

			if (this.id === null) {
				doCreate(this.ENTITY_NAME, {
					"name":     this.name,
					"position": this.parentId
				})
					.then(() => {
						this.load();

						this.$refs.closeModal.click();
						this.setNew();
					})
					.catch(error => {
						console.error(error);
						toastr.error('Si è verificato un errore');
					});
			} else {
				doUpdate(this.ENTITY_NAME, this.id, {
					"name": this.name
				})
					.then(() => {
						this.load();

						this.$refs.closeModal.click();
						this.setNew();
					})
					.catch(error => {
						console.error(error);
						toastr.error('Si è verificato un errore');
					});
			}
		},
		canBeDeleted:    function (item) {
			return item.childrens.length <= 0 && item.canBeDeleted;
		},
		openModal:       function (event, item) {
			event.preventDefault();

			this.setNew(item.id);

			this.subOperation = true;
			this.$refs['open' + this.ENTITY_NAME + 'Modal'].click();
			this.subOperation = false;
		},
		openModalEdit:   function (event, item) {
			event.preventDefault();

			this.setEdit(item);

			this.subOperation = true;
			this.$refs['open' + this.ENTITY_NAME + 'Modal'].click();
			this.subOperation = false;
		},
		openModalDelete: function (event, item) {
			event.preventDefault();

			this.setEdit(item);

			this.$refs['open' + this.ENTITY_NAME + 'ModalDelete'].click();
		},
		deleteItem:      function () {
			doDelete(this.ENTITY_NAME, this.id)
				.then(() => {
					this.load();

					this.$refs.closeModalDelete.click();

					this.id       = null;
					this.name     = null;
					this.parentId = null;
				})
				.catch(error => {
					console.error(error);
					toastr.error('Si è verificato un errore');
				});
		}
	}
}
</script>

<style scoped lang="scss">
.btn-outline-danger {
	&:disabled {
		color:        var(--bs-gray);
		border-color: var(--bs-gray);
	}
}
</style>
