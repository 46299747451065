import Vue     from 'vue';
import Vuex    from 'vuex';
import Cookies from 'js-cookie';
import router  from '../router'

Vue.use(Vuex);

export default new Vuex.Store({
    state:     {
        sidebarOpen: (localStorage.getItem('sidebarOpen') || 'true') === 'true',
        user:        Cookies.get('user') || null,
        loading:     true,
        error:       false,
        maintenance: false
    },
    mutations: {
        toggleSidebar: function () {
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sidebarOpen', !document.body.classList.contains('sb-sidenav-toggled'));
        },
        login:         function (store, args) {
            const exp                   = JSON.parse(atob(args.jwt.split('.')[1])).exp;
            const {id, username, email} = args.user;

            store.user = btoa(JSON.stringify({jwt: args.jwt, id, username, email}));

            // save user in cookie
            Cookies.set('user', store.user, {expires: new Date(exp * 1000)});

            // go to home
            router.push('/');
        },
        logout:        function (store) {
            Cookies.remove('user');
            store.user = null;
            router.push('/login');
        },
        setLoading:    function (store, value) {
            store.loading = !!value;
        },
        setError:      function (store, value) {
            store.error = !!value;
        },
        setMaintenance:      function (store, value) {
            store.maintenance = !!value;
        }
    },
    actions:   {
        setLoaded: function (store) {
            store.commit('setLoading', false);
        },
        setError:  function (store) {
            store.commit('setError', true);
        }
    },
    modules:   {},
    getters:   {
        isAuthenticated: state => {
            return !!state.user;
        },
        user:            state => {
            const {id, username, email} = JSON.parse(atob(state.user));
            return {id, username, email};
        },
        jwt:             state => {
            const {jwt} = JSON.parse(atob(state.user));
            return jwt;
        },
        isLoading:       state => {
            return state.loading;
        },
        isAppError:      state => {
            return state.error;
        }
    }
});
