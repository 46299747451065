<template>
	<div>
		<h1 class="mt-4">
			<i v-if="ICON" :class="ICON"></i>
			{{ TITLE }}
		</h1>

		<div class="mb-3">
			<button class="btn btn-success"
					data-bs-toggle="modal"
					:data-bs-target="'#' + ENTITY_NAME + 'Modal'"
					@click="setNew"
			>
				<i class="fas fa-plus"></i>
				Aggiungi
			</button>
		</div>

		<div v-show="!loading">
			<table v-show="!loading" class="table table-striped table-hover table-bordered">
				<thead>
				<tr>
					<th scope="col">Nome</th>
					<th scope="col">Posizione</th>
					<th scope="col">Operazioni</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(item, index) in items"
					:key="ENTITY_NAME + index"
				>
					<td class="align-middle">{{ item.name }}</td>
					<td class="align-middle">{{ item.position ? item.position.name : '' }}</td>
					<td class="text-center align-middle">
						<div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
							<button class="btn btn-info"
									data-bs-toggle="modal"
									:data-bs-target="'#' + ENTITY_NAME + 'ModalShow'"
									@click="setShowing(item)"
							>
								<i class="fas fa-info-circle"></i>
							</button>
							<button class="btn btn-primary"
									data-bs-toggle="modal"
									:data-bs-target="'#' + ENTITY_NAME + 'Modal'"
									@click="setEdit(item)"
							>
								<i class="fas fa-pencil-alt"></i>
							</button>
							<button class="btn btn-danger"
									data-bs-toggle="modal"
									:data-bs-target="'#' + ENTITY_NAME + 'ModalDelete'"
									@click="setEdit(item)"
							>
								<i class="fas fa-trash-alt"></i>
							</button>
						</div>
					</td>
				</tr>
				<tr v-if="items.length === 0">
					<td colspan="3" class="text-center table-info">{{ LABEL_EMPTY }}</td>
				</tr>
				</tbody>
			</table>
		</div>
		<Loader v-show="loading"/>

		<div class="modal fade" :id="ENTITY_NAME + 'ModalShow'" tabindex="-1" :aria-labelledby="ENTITY_NAME + 'ModalShowLabel'" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div v-if="showing" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" :id="ENTITY_NAME + 'ModalShowLabel'">
							{{ showing.name }}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div v-if="showing.position" class="col-md-4 mb-3">
								<dl>
									<dt>Posizione</dt>
									<dd>{{ showing.position.name }}</dd>
								</dl>
							</div>
							<div v-if="showing.article" class="col-md-4 mb-3">
								<dl>
									<dt>Articolo</dt>
									<dd>{{ showing.article }}</dd>
								</dl>
							</div>
							<div v-if="showing.color" class="col-md-4 mb-3">
								<dl>
									<dt>Colore</dt>
									<dd>{{ showing.color }}</dd>
								</dl>
							</div>
							<div v-if="showing.finishing" class="col-md-4 mb-3">
								<dl>
									<dt>Finissaggio</dt>
									<dd>{{ showing.finishing }}</dd>
								</dl>
							</div>
							<div v-if="showing.dyeing" class="col-md-4 mb-3">
								<dl>
									<dt>Tintura</dt>
									<dd>{{ showing.dyeing }}</dd>
								</dl>
							</div>
							<div v-if="showing.hand" class="col-md-4 mb-3">
								<dl>
									<dt>Mano</dt>
									<dd>{{ showing.hand }}</dd>
								</dl>
							</div>
							<div v-if="showing.use" class="col-md-4 mb-3">
								<dl>
									<dt>Uso</dt>
									<dd>{{ showing.use }}</dd>
								</dl>
							</div>
							<div v-if="showing.motif" class="col-md-4 mb-3">
								<dl>
									<dt>Motivo</dt>
									<dd>{{ showing.motif }}</dd>
								</dl>
							</div>
							<div v-if="showing.thread_title" class="col-md-4 mb-3">
								<dl>
									<dt>Titolo filo</dt>
									<dd>{{ showing.thread_title }}</dd>
								</dl>
							</div>
							<div v-if="showing.print" class="col-md-4 mb-3">
								<dl>
									<dt>Stampa</dt>
									<dd>{{ showing.print }}</dd>
								</dl>
							</div>
							<div v-if="showing.elasticity_type" class="col-md-4 mb-3">
								<dl>
									<dt>Tipo elasticità</dt>
									<dd>{{ showing.elasticity_type }}</dd>
								</dl>
							</div>
							<div v-if="showing.price" class="col-md-4 mb-3">
								<dl>
									<dt>Prezzo</dt>
									<dd>{{ showing.price }}</dd>
								</dl>
							</div>
						</div>
						<div class="row">
							<div v-if="showing.weight_per_meter" class="col-md-4 mb-3">
								<dl>
									<dt>Peso al metro
										<span v-if="showing.hypothetical_weight_per_meter">(ipotetico)</span></dt>
									<dd>
										<span>{{ showing.weight_per_meter }}</span>
										<span v-if="showing.unit_measure_weight_per_meter"> {{ showing.unit_measure_weight_per_meter }}</span>
									</dd>
								</dl>
							</div>
							<div v-if="showing.weight_per_linear_meter" class="col-md-4 mb-3">
								<dl>
									<dt>Peso al metro lineare
										<span v-if="showing.hypothetical_weight_per_linear_meter">(ipotetico)</span>
									</dt>
									<dd>
										<span>{{ showing.weight_per_linear_meter }}</span>
										<span v-if="showing.unit_measure_weight_per_linear_meter"> {{ showing.unit_measure_weight_per_linear_meter }}</span>
									</dd>
								</dl>
							</div>
							<div v-if="showing.height" class="col-md-4 mb-3">
								<dl>
									<dt>Altezza</dt>
									<dd>
										<span>{{ showing.height }}</span>
										<span v-if="showing.unit_measure_height"> {{ showing.unit_measure_height }}</span>
									</dd>
								</dl>
							</div>
						</div>
						<div class="row">
							<div v-if="showing.fabric_type" class="col-md-4 mb-3">
								<dl>
									<dt>Tipo tessuto</dt>
									<dd>{{ showing.fabric_type.name }}</dd>
								</dl>
							</div>
							<div v-if="showing.armor" class="col-md-4 mb-3">
								<dl>
									<dt>Armatura</dt>
									<dd>{{ showing.armor.name }}</dd>
								</dl>
							</div>
							<div v-if="showing.supplier" class="col-md-4 mb-3">
								<dl>
									<dt>Fornitore</dt>
									<dd>{{ showing.supplier.name }}</dd>
								</dl>
							</div>
						</div>

						<div v-if="showing.washings.length > 0">
							<p class="mb-0"><b>Lavaggi</b></p>
							<div class="row">
								<div class="col-md-4 mb-3 text-center"
									 v-for="washing in showing.washings"
									 :key="'showwashing' + showing.id + '-' + washing.id"
								>
									{{washing.name}}
									<br>
									<img v-if="washing.image" :src="washing.image" :alt="washing.name" height="50">
								</div>
							</div>
						</div>

						<div class="row">
							<div v-if="showing.composition" class="col-md-12 mb-3">
								<dl>
									<dt>Composizione <span v-if="showing.hypothetical_composition">(ipotetica)</span>
									</dt>
									<dd>{{ showing.composition }}</dd>
								</dl>
							</div>
							<div v-if="showing.technical_features" class="col-md-12 mb-3">
								<dl>
									<dt>Caratteristiche tecniche</dt>
									<dd>{{ showing.technical_features }}</dd>
								</dl>
							</div>
							<div v-if="showing.description" class="col-md-12 mb-3">
								<dl>
									<dt>Descrizione</dt>
									<dd>{{ showing.description }}</dd>
								</dl>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
						>
							Chiudi
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" :id="ENTITY_NAME + 'Modal'" tabindex="-1" :aria-labelledby="ENTITY_NAME + 'ModalLabel'" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" :id="ENTITY_NAME + 'ModalLabel'">
							{{ id ? LABEL_EDIT : LABEL_NEW }}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<form @submit="save">
						<div class="modal-body">
							<div class="row">
								<div class="col-md-12 mb-3">
									<div class="form-floating">
										<select class="form-select"
												ref="positionSelect"
												:id="'position' + ENTITY_NAME"
												aria-label="Posizione"
												v-model="position"
										>
											<option selected value="">- Seleziona -</option>
											<option v-for="position in listPositions"
													:key="'listPositions' + position.id"
													:value="position.id"
											>
												{{ position.name }}
											</option>
										</select>
										<label :for="'position' + ENTITY_NAME">Posizione</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'name' + ENTITY_NAME"
											   type="text"
											   placeholder="Nome *"
											   v-model.trim="name"
											   required
										/>
										<label :for="'name' + ENTITY_NAME">Nome *</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'article' + ENTITY_NAME"
											   type="text"
											   placeholder="Articolo"
											   v-model.trim="article"
										/>
										<label :for="'article' + ENTITY_NAME">Articolo</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'color' + ENTITY_NAME"
											   type="text"
											   placeholder="Colore"
											   v-model.trim="color"
										/>
										<label :for="'color' + ENTITY_NAME">Colore</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating ">
										<input class="form-control"
											   :id="'finishing' + ENTITY_NAME"
											   type="text"
											   placeholder="Finissaggio"
											   v-model.trim="finishing"
										/>
										<label :for="'finishing' + ENTITY_NAME">Finissaggio</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'dyeing' + ENTITY_NAME"
											   type="text"
											   placeholder="Tintura"
											   v-model.trim="dyeing"
										/>
										<label :for="'dyeing' + ENTITY_NAME">Tintura</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'hand' + ENTITY_NAME"
											   type="text"
											   placeholder="Mano"
											   v-model.trim="hand"
										/>
										<label :for="'hand' + ENTITY_NAME">Mano</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'use' + ENTITY_NAME"
											   type="text"
											   placeholder="Uso"
											   v-model.trim="use"
										/>
										<label :for="'use' + ENTITY_NAME">Uso</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'motif' + ENTITY_NAME"
											   type="text"
											   placeholder="Motivo"
											   v-model.trim="motif"
										/>
										<label :for="'motif' + ENTITY_NAME">Motivo</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'thread_title' + ENTITY_NAME"
											   type="text"
											   placeholder="Titolo filo"
											   v-model.trim="thread_title"
										/>
										<label :for="'thread_title' + ENTITY_NAME">Titolo filo</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'print' + ENTITY_NAME"
											   type="text"
											   placeholder="Stampa"
											   v-model.trim="print"
										/>
										<label :for="'print' + ENTITY_NAME">Stampa</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'elasticity_type' + ENTITY_NAME"
											   type="text"
											   placeholder="Tipo elasticità"
											   v-model.trim="elasticity_type"
										/>
										<label :for="'elasticity_type' + ENTITY_NAME">Tipo elasticità</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'price' + ENTITY_NAME"
											   type="number"
											   placeholder="Prezzo"
											   v-model="price"
										/>
										<label :for="'price' + ENTITY_NAME">Prezzo</label>
									</div>
								</div>
								<div class="col-md-9 mb-3">
									<div class="form-floating">
										<textarea class="form-control"
												  placeholder="Composizione"
												  :id="'composition' + ENTITY_NAME"
												  v-model.trim="composition"
										></textarea>
										<label :for="'composition' + ENTITY_NAME">Composizione</label>
									</div>
								</div>
								<div class="col-md-3 mb-3">
									<div class="form-check form-switch pt-md-3">
										<input class="form-check-input"
											   type="checkbox"
											   role="switch"
											   :id="'hypothetical_composition' + ENTITY_NAME"
											   v-model="hypothetical_composition"
										>
										<label class="form-check-label"
											   :for="'hypothetical_composition' + ENTITY_NAME"
										>Composizione ipotetica</label>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'weight_per_meter' + ENTITY_NAME"
											   type="number"
											   placeholder="Peso al metro"
											   v-model="weight_per_meter"
										/>
										<label :for="'weight_per_meter' + ENTITY_NAME">Peso al metro</label>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'unit_measure_weight_per_meter' + ENTITY_NAME"
											   type="text"
											   placeholder="Peso al metro (unità di misura)"
											   v-model.trim="unit_measure_weight_per_meter"
										/>
										<label :for="'unit_measure_weight_per_meter' + ENTITY_NAME">Peso al metro (unità di misura)</label>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-check form-switch pt-md-3">
										<input class="form-check-input"
											   type="checkbox"
											   role="switch"
											   :id="'hypothetical_weight_per_meter' + ENTITY_NAME"
											   v-model="hypothetical_weight_per_meter"
										>
										<label class="form-check-label"
											   :for="'hypothetical_weight_per_meter' + ENTITY_NAME"
										>Peso al metro (ipotetico)</label>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'weight_per_linear_meter' + ENTITY_NAME"
											   type="number"
											   placeholder="Peso al metro lineare"
											   v-model="weight_per_linear_meter"
										/>
										<label :for="'weight_per_linear_meter' + ENTITY_NAME">Peso al metro lineare</label>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'unit_measure_weight_per_linear_meter' + ENTITY_NAME"
											   type="text"
											   placeholder="Peso al metro lineare (unità di misura)"
											   v-model.trim="unit_measure_weight_per_linear_meter"
										/>
										<label :for="'unit_measure_weight_per_linear_meter' + ENTITY_NAME">Peso al metro lineare (unità di misura)</label>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-check form-switch pt-md-3">
										<input class="form-check-input"
											   type="checkbox"
											   role="switch"
											   :id="'hypothetical_weight_per_linear_meter' + ENTITY_NAME"
											   v-model="hypothetical_weight_per_linear_meter"
										>
										<label class="form-check-label"
											   :for="'hypothetical_weight_per_linear_meter' + ENTITY_NAME"
										>Peso al metro lineare (ipotetico)</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'height' + ENTITY_NAME"
											   type="number"
											   placeholder="Altezza"
											   v-model="height"
										/>
										<label :for="'height' + ENTITY_NAME">Altezza</label>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<div class="form-floating">
										<input class="form-control"
											   :id="'unit_measure_height' + ENTITY_NAME"
											   type="text"
											   placeholder="Altezza (unità di misura)"
											   v-model.trim="unit_measure_height"
										/>
										<label :for="'unit_measure_height' + ENTITY_NAME">Altezza (unità di misura)</label>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-floating">
										<select class="form-select"
												:id="'fabric_type' + ENTITY_NAME"
												aria-label="Tipo tessuto"
												v-model="fabric_type"
										>
											<option selected value="">- Seleziona -</option>
											<option v-for="item in listFabricTypes"
													:key="'listFabricTypes' + item.id"
													:value="item.id"
											>
												{{ item.name }}
											</option>
										</select>
										<label :for="'fabric_type' + ENTITY_NAME">Tipo tessuto</label>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-floating">
										<select class="form-select"
												:id="'armor' + ENTITY_NAME"
												aria-label="Armatura"
												v-model="armor"
										>
											<option selected value="">- Seleziona -</option>
											<option v-for="item in listArmors"
													:key="'listArmors' + item.id"
													:value="item.id"
											>
												{{ item.name }}
											</option>
										</select>
										<label :for="'fabric_type' + ENTITY_NAME">Armatura</label>
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<div class="form-floating">
										<select class="form-select"
												:id="'supplier' + ENTITY_NAME"
												aria-label="Fornitore"
												v-model="supplier"
										>
											<option selected value="">- Seleziona -</option>
											<option v-for="item in listSuppliers"
													:key="'listSuppliers' + item.id"
													:value="item.id"
											>
												{{ item.name }}
											</option>
										</select>
										<label :for="'supplier' + ENTITY_NAME">Fornitore</label>
									</div>
								</div>
								<div class="col-md-12 mb-3">
									<p class="mb-0"><b>Lavaggi</b></p>
									<div class="form-check form-check-inline"
										 v-for="item in listWashings"
										 :key="'listWashings' + item.id"
									>
										<input class="form-check-input"
											   type="checkbox"
											   :value="item.id"
											   :id="'washings' + ENTITY_NAME + item.id"
											   @change="selectWashing"
											   ref="checkboxWashing"
											   :checked="washings.indexOf(item.id) > -1"
										>
										<label class="form-check-label"
											   :for="'washings' + ENTITY_NAME + item.id"
										>
											{{ item.name }}
										</label>
									</div>
								</div>
								<div class="col-md-12 mb-3">
									<div class="form-floating">
										<textarea class="form-control"
												  placeholder="Caratteristiche tecniche"
												  :id="'technical_features' + ENTITY_NAME"
												  v-model.trim="technical_features"
										></textarea>
										<label :for="'technical_features' + ENTITY_NAME">Caratteristiche tecniche</label>
									</div>
								</div>
								<div class="col-md-12 mb-3">
									<div class="form-floating">
										<textarea class="form-control"
												  placeholder="Descrizione"
												  :id="'description' + ENTITY_NAME"
												  v-model.trim="description"
										></textarea>
										<label :for="'description' + ENTITY_NAME">Descrizione</label>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button"
									class="btn btn-secondary"
									data-bs-dismiss="modal"
									ref="closeModal"
							>
								Annulla
							</button>
							<button type="submit" class="btn btn-success">
								Salva
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" :id="ENTITY_NAME + 'ModalDelete'" tabindex="-1" :aria-labelledby="ENTITY_NAME + 'ModalDeleteLabel'" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" :id="ENTITY_NAME + 'ModalDeleteLabel'">
							{{ LABEL_DELETE }}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						Eliminare {{ name }}?
					</div>
					<div class="modal-footer">
						<button type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
								ref="closeModalDelete"
						>
							Annulla
						</button>
						<button type="button"
								class="btn btn-danger"
								@click="deleteItem"
						>
							<i class="fas fa-trash-alt"></i>
							Elimina
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader                                 from "../components/Loader";
import {doCreate, doDelete, doFind, doUpdate} from "../utils/client";
import {toastr}                               from "../utils/toastr";
import Armors                                 from "./Armors";
import FabricTypes                            from "./FabricTypes";
import Positions                              from "./Positions";
import Suppliers                              from "./Suppliers";
import Washings                               from "./Washings";

export default {
	name:       'Fabrics',
	components: {Loader},
	data() {
		return {
			ENTITY_NAME:  'fabrics',
			ICON:         'fas fa-layer-group',
			TITLE:        'Tessuti',
			LABEL_NEW:    'Nuovo tessuto',
			LABEL_EDIT:   'Modifica tessuto',
			LABEL_DELETE: 'Elimina tessuto',
			LABEL_EMPTY:  'Nessun tessuto trovato',

			loading:                              true,
			items:                                [],
			id:                                   null,
			name:                                 null,
			article:                              null,
			color:                                null,
			finishing:                            null,
			dyeing:                               null,
			hand:                                 null,
			use:                                  null,
			motif:                                null,
			thread_title:                         null,
			print:                                null,
			elasticity_type:                      null,
			fabric_type:                          null,
			composition:                          null,
			hypothetical_composition:             null,
			price:                                null,
			weight_per_meter:                     null,
			unit_measure_weight_per_meter:        null,
			hypothetical_weight_per_meter:        null,
			weight_per_linear_meter:              null,
			unit_measure_weight_per_linear_meter: null,
			hypothetical_weight_per_linear_meter: null,
			height:                               null,
			unit_measure_height:                  null,
			armor:                                null,
			supplier:                             null,
			technical_features:                   null,
			description:                          null,
			position:                             null,
			washings:                             [],

			listFabricTypes: [],
			listArmors:      [],
			listSuppliers:   [],
			listPositions:   [],
			listWashings:    [],

			showing: null
		};
	},
	computed: {
		appLoading: function () {
			return this.$store.getters.isLoading;
		}
	},
	watch:    {
		appLoading: function (newData, oldData) {
			if (!newData && oldData) {
				this.load();
			}
		}
	},
	mounted() {
		this.load();
	},
	methods: {
		load:                 function () {
			doFind(Washings.data().ENTITY_NAME).then(r => this.listWashings = this.sortItems(r.data));
			doFind(FabricTypes.data().ENTITY_NAME).then(r => this.listFabricTypes = this.sortItems(r.data));
			doFind(Armors.data().ENTITY_NAME).then(r => this.listArmors = this.sortItems(r.data));
			doFind(Suppliers.data().ENTITY_NAME).then(r => this.listSuppliers = this.sortItems(r.data));
			doFind(Positions.data().ENTITY_NAME).then(r => {
				const positionsTree = Positions.methods.listToTree(r.data);
				let items           = [];

				for (let i = 0; i < positionsTree.length; i++) {
					items = this.getPositionTreeNames(positionsTree[i], items);
				}

				this.listPositions = this.sortItems(items);

				doFind(this.ENTITY_NAME)
					.then(response => {
						this.items = response.data.map(r => {
							if (r.position) {
								r.position.name = this.listPositions.find(i => i.id === r.position.id).name;
							}

							return r;
						});

						this.sortItems(this.items);
						this.loading = false;
					})
					.catch(error => {
						console.error(error);
						if (!this.appLoading) {
							toastr.error('Si è verificato un errore');
						}
						this.loading = false;
					});
			});
		},
		getPositionTreeNames: function (position, items) {
			items.push({id: position.id, name: position.name});

			if (position.childrens.length > 0) {
				for (let i = 0; i < position.childrens.length; i++) {
					position.childrens[i].name = [position.name, position.childrens[i].name].join(' > ');
					items                      = this.getPositionTreeNames(position.childrens[i], items);
				}
			}

			return items;
		},
		sortItems:            function (items, key = 'name') {
			items.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
			return items;
		},
		setNew:               function () {
			this.id                                   = null;
			this.name                                 = null;
			this.article                              = null;
			this.color                                = null;
			this.finishing                            = null;
			this.dyeing                               = null;
			this.hand                                 = null;
			this.use                                  = null;
			this.motif                                = null;
			this.thread_title                         = null;
			this.print                                = null;
			this.elasticity_type                      = null;
			this.fabric_type                          = null;
			this.composition                          = null;
			this.hypothetical_composition             = null;
			this.price                                = null;
			this.weight_per_meter                     = null;
			this.unit_measure_weight_per_meter        = null;
			this.hypothetical_weight_per_meter        = null;
			this.weight_per_linear_meter              = null;
			this.unit_measure_weight_per_linear_meter = null;
			this.hypothetical_weight_per_linear_meter = null;
			this.height                               = null;
			this.unit_measure_height                  = null;
			this.armor                                = null;
			this.supplier                             = null;
			this.technical_features                   = null;
			this.description                          = null;
			this.position                             = null;
			this.washings                             = [];
		},
		setEdit:              function (item) {
			this.id                                   = item.id;
			this.name                                 = item.name;
			this.article                              = item.article;
			this.color                                = item.color;
			this.finishing                            = item.finishing;
			this.dyeing                               = item.dyeing;
			this.hand                                 = item.hand;
			this.use                                  = item.use;
			this.motif                                = item.motif;
			this.thread_title                         = item.thread_title;
			this.print                                = item.print;
			this.elasticity_type                      = item.elasticity_type;
			this.fabric_type                          = item.fabric_type ? item.fabric_type.id : null;
			this.composition                          = item.composition;
			this.hypothetical_composition             = item.hypothetical_composition;
			this.price                                = item.price;
			this.weight_per_meter                     = item.weight_per_meter;
			this.unit_measure_weight_per_meter        = item.unit_measure_weight_per_meter;
			this.hypothetical_weight_per_meter        = item.hypothetical_weight_per_meter;
			this.weight_per_linear_meter              = item.weight_per_linear_meter;
			this.unit_measure_weight_per_linear_meter = item.unit_measure_weight_per_linear_meter;
			this.hypothetical_weight_per_linear_meter = item.hypothetical_weight_per_linear_meter;
			this.height                               = item.height;
			this.unit_measure_height                  = item.unit_measure_height;
			this.armor                                = item.armor ? item.armor.id : null;
			this.supplier                             = item.supplier ? item.supplier.id : null;
			this.technical_features                   = item.technical_features;
			this.description                          = item.description;
			this.position                             = item.position ? item.position.id : null;
			this.washings                             = item.washings.map(w => parseInt(w.id));
		},
		setShowing:           function (item) {
			this.showing = item;
		},
		save:                 function (event) {
			event.preventDefault();

			if (this.id == null) {
				doCreate(this.ENTITY_NAME, {
					'id':                                   this.id,
					'name':                                 this.name,
					'article':                              this.article,
					'color':                                this.color,
					'finishing':                            this.finishing,
					'dyeing':                               this.dyeing,
					'hand':                                 this.hand,
					'use':                                  this.use,
					'motif':                                this.motif,
					'thread_title':                         this.thread_title,
					'print':                                this.print,
					'elasticity_type':                      this.elasticity_type,
					'fabric_type':                          this.fabric_type,
					'composition':                          this.composition,
					'hypothetical_composition':             this.hypothetical_composition,
					'price':                                this.price,
					'weight_per_meter':                     this.weight_per_meter,
					'unit_measure_weight_per_meter':        this.unit_measure_weight_per_meter,
					'hypothetical_weight_per_meter':        this.hypothetical_weight_per_meter,
					'weight_per_linear_meter':              this.weight_per_linear_meter,
					'unit_measure_weight_per_linear_meter': this.unit_measure_weight_per_linear_meter,
					'hypothetical_weight_per_linear_meter': this.hypothetical_weight_per_linear_meter,
					'height':                               this.height,
					'unit_measure_height':                  this.unit_measure_height,
					'armor':                                this.armor,
					'supplier':                             this.supplier,
					'technical_features':                   this.technical_features,
					'description':                          this.description,
					'position':                             this.position,
					'washings':                             this.washings
				})
					.then(response => {
						this.items.push(response.data);
						this.sortItems(this.items);

						this.$refs.closeModal.click();
						this.setNew();
					})
					.catch(error => {
						console.error(error);
						toastr.error('Si è verificato un errore');
					});
			} else {
				const data = {
					'name':                                 this.name,
					'article':                              this.article,
					'color':                                this.color,
					'finishing':                            this.finishing,
					'dyeing':                               this.dyeing,
					'hand':                                 this.hand,
					'use':                                  this.use,
					'motif':                                this.motif,
					'thread_title':                         this.thread_title,
					'print':                                this.print,
					'elasticity_type':                      this.elasticity_type,
					'fabric_type':                          this.fabric_type,
					'composition':                          this.composition,
					'hypothetical_composition':             this.hypothetical_composition,
					'price':                                this.price,
					'weight_per_meter':                     this.weight_per_meter,
					'unit_measure_weight_per_meter':        this.unit_measure_weight_per_meter,
					'hypothetical_weight_per_meter':        this.hypothetical_weight_per_meter,
					'weight_per_linear_meter':              this.weight_per_linear_meter,
					'unit_measure_weight_per_linear_meter': this.unit_measure_weight_per_linear_meter,
					'hypothetical_weight_per_linear_meter': this.hypothetical_weight_per_linear_meter,
					'height':                               this.height,
					'unit_measure_height':                  this.unit_measure_height,
					'armor':                                this.armor,
					'supplier':                             this.supplier,
					'technical_features':                   this.technical_features,
					'description':                          this.description,
					'position':                             this.position,
					'washings':                             this.washings
				};
				const dataKeys = Object.keys(data);
				for (let i = 0; i < dataKeys.length; i++) {
					if (data[dataKeys[i]] !== '') {
						continue;
					}
					data[dataKeys[i]] = null;
				}

				doUpdate(this.ENTITY_NAME, this.id, data)
					.then(response => {
						const i       = this.items.findIndex(elm => elm.id === this.id);
						this.items[i] = response.data;
						this.sortItems(this.items);

						this.$refs.closeModal.click();
						this.setNew();
					})
					.catch(error => {
						console.error(error);
						toastr.error('Si è verificato un errore');
					});
			}
		},
		deleteItem:           function (event) {
			event.preventDefault();

			doDelete(this.ENTITY_NAME, this.id)
				.then(() => {
					const i = this.items.findIndex(elm => elm.id === this.id);
					this.items.splice(i, 1);
					this.sortItems(this.items);

					this.$refs.closeModalDelete.click();
					this.setNew();
				})
				.catch(error => {
					console.error(error);
					toastr.error('Si è verificato un errore');
				});
		},
		selectWashing:        function (event) {
			event.preventDefault();
			this.washings = this.$refs.checkboxWashing.filter(c => c.checked).map(c => parseInt(c.value));
		}
	}
}
</script>

<style lang="scss">
dl, dd, dt {
	margin:  0;
	padding: 0;
}
</style>
