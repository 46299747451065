<template>
	<div>
		<h1 class="mt-4">
			<i v-if="ICON" :class="ICON"></i>
			{{ TITLE }}
		</h1>

		<div v-show="!loading">
			<div class="mb-3">
				<div class="form-check form-switch">
					<input class="form-check-input" type="checkbox" role="switch" id="hideEmptySections" v-model="hideEmptySections">
					<label class="form-check-label" for="hideEmptySections">Nascondi sezioni vuote</label>
				</div>
			</div>

			<div v-for="entity in entities"
				 :key="'search' + entity.key"
			>
				<div class="mb-5"
					 v-if="!hideEmptySections || entity.results.length > 0"
				>
					<h4>
						<i v-if="entity.icon" :class="entity.icon"></i>
						{{ entity.title }} ({{ entity.results.length }})
					</h4>
					<table class="table table-striped table-hover table-bordered">
						<thead>
						<tr>
							<th scope="col"
								v-for="(entityKey, index) in entity.entityKeys"
								:key="'searchHeader' + index"
								v-html="entityKey.label"
							></th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(item, index) in entity.results"
							:key="'search' + entity.key + index"
						>
							<td class="align-middle"
								v-for="(entityKey, index) in entity.entityKeys"
								:key="'searchRow' + index"
							>
								<span v-if="entityKey.type === 'text'">{{ item[entityKey.key] }}</span>
								<div v-else-if="entityKey.type === 'image'" class="text-center">
									<img :src="item[entityKey.key]" alt="" height="50">
								</div>
							</td>
						</tr>
						<tr v-if="entity.results.length === 0">
							<td :colspan="entity.entityKeys.length" class="text-center table-info">{{ entity.labelEmpty }}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<Loader v-show="loading"/>
	</div>
</template>

<script>
import Loader      from "../components/Loader";
import {doFind}    from "../utils/client";
import Armors      from "./Armors";
import Fabrics     from "./Fabrics";
import FabricTypes from "./FabricTypes";
import Positions   from "./Positions";
import Suppliers   from "./Suppliers";
import Washings    from "./Washings";

export default {
	name:       "Search",
	components: {Loader},
	data() {
		return {
			ICON:  'fas fa-search',
			TITLE: 'Ricerca',

			loading:           true,
			hideEmptySections: false,
			entities:          [
				{
					entityKeys: [{key: 'id', label: 'ID', type: 'text'}, {key: 'name', label: 'Nome', type: 'text'}],
					key:        Armors.data().ENTITY_NAME,
					title:      Armors.data().TITLE,
					icon:       Armors.data().ICON,
					labelEmpty: Armors.data().LABEL_EMPTY,
					results:    []
				},
				{
					entityKeys: [{key: 'id', label: 'ID', type: 'text'}, {key: 'name', label: 'Nome', type: 'text'}],
					key:        Fabrics.data().ENTITY_NAME,
					title:      Fabrics.data().TITLE,
					icon:       Fabrics.data().ICON,
					labelEmpty: Fabrics.data().LABEL_EMPTY,
					results:    []
				},
				{
					entityKeys: [{key: 'id', label: 'ID', type: 'text'}, {key: 'name', label: 'Nome', type: 'text'}],
					key:        FabricTypes.data().ENTITY_NAME,
					title:      FabricTypes.data().TITLE,
					icon:       FabricTypes.data().ICON,
					labelEmpty: FabricTypes.data().LABEL_EMPTY,
					results:    []
				},
				{
					entityKeys: [{key: 'id', label: 'ID', type: 'text'}, {key: 'name', label: 'Nome', type: 'text'}],
					key:        Positions.data().ENTITY_NAME,
					title:      Positions.data().TITLE,
					icon:       Positions.data().ICON,
					labelEmpty: Positions.data().LABEL_EMPTY,
					results:    []
				},
				{
					entityKeys: [{key: 'id', label: 'ID', type: 'text'}, {key: 'name', label: 'Nome', type: 'text'}],
					key:        Suppliers.data().ENTITY_NAME,
					title:      Suppliers.data().TITLE,
					icon:       Suppliers.data().ICON,
					labelEmpty: Suppliers.data().LABEL_EMPTY,
					results:    []
				},
				{
					entityKeys: [{key: 'id', label: 'ID', type: 'text'}, {key: 'name', label: 'Nome', type: 'text'}, {
						key:   'description',
						label: 'Descrizione',
						type:  'text'
					}, {key: 'category', label: 'Categoria', type: 'text'}, {
						key:   'image',
						label: 'Immagine',
						type:  'image'
					}],
					key:        Washings.data().ENTITY_NAME,
					title:      Washings.data().TITLE,
					icon:       Washings.data().ICON,
					labelEmpty: Washings.data().LABEL_EMPTY,
					results:    []
				}
			]
		};
	},
	computed: {
		decoded:    function () {
			try {
				return atob(this.$route.params.string).trim();
			} catch (e) {
				return '';
			}
		},
		appLoading: function () {
			return this.$store.getters.isLoading;
		},
		routePath:  function () {
			return this.$route.path;
		}
	},
	watch:    {
		appLoading: function (newData, oldData) {
			if (!newData && oldData) {
				this.load();
			}
		},
		routePath:  function (newData, oldData) {
			if (newData !== oldData) {
				this.load();
			}
		}
	},
	mounted() {
		if (!this.decoded) {
			this.$router.push('/');
		} else {
			this.load();
		}
	},
	methods: {
		load: function () {
			const searches = [];

			this.entities.map(e => {
				searches.push(doFind(e.key, {_q: this.decoded}).then(response => e.results = response.data));
			});

			Promise.all(searches).then(() => this.loading = false);
		}
	}
}
</script>

<style scoped>

</style>
