<template>
	<nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
		<!-- Navbar Brand-->
		<router-link class="navbar-brand ps-3" to="/" v-html="title"></router-link>

		<!-- Sidebar Toggle-->
		<button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0 text-light" @click="toggleSidebar" href="#!">
			<i class="fas fa-bars"></i></button>

		<!-- Navbar Search-->
		<form @submit="search" class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
			<div class="input-group">
				<input class="form-control"
					   type="search"
					   placeholder="Ricerca..."
					   aria-label="Ricerca..."
					   aria-describedby="btnNavbarSearch"
					   v-model.trim="searchString"
					   required
				/>
				<button class="btn btn-primary"
						id="btnNavbarSearch"
						type="submit"
				>
					<i class="fas fa-search"></i>
				</button>
			</div>
		</form>

		<!-- Navbar-->
		<ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
			<li class="nav-item dropdown">
				<a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
					<i class="fas fa-user fa-fw"></i> {{ username }}
				</a>
				<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
					<li><a class="dropdown-item" href="#" @click="logout">Logout</a></li>
				</ul>
			</li>
		</ul>
	</nav>
</template>

<script>
import * as siteConfig from '../../site.config';

export default {
	name: "Navbar",
	data() {
		return {
			title:        siteConfig.title,
			searchString: null
		};
	},
	computed: {
		username:  function () {
			return this.$store.getters.user.username;
		},
		routePath: function () {
			return this.$route.path;
		}
	},
	watch:    {
		routePath: function (newData, oldData) {
			if (newData !== oldData) {
				this.setSearchString();
			}
		}
	},
	mounted() {
		this.setSearchString();
	},
	methods: {
		setSearchString: function () {
			if (this.routePath.split('/')[1] === 'search') {
				this.searchString = atob(this.$route.params.string).trim();
			} else {
				this.searchString = null;
			}
		},
		toggleSidebar:   function (event) {
			event.preventDefault();

			this.$store.commit('toggleSidebar');
		},
		logout:          function (event) {
			event.preventDefault();

			this.$store.commit('logout');
		},
		search:          function (event) {
			event.preventDefault();

			if (this.routePath !== '/search/' + btoa(this.searchString)) {
				// go to search
				this.$router.push('/search/' + btoa(this.searchString));
			}
		}
	}
}
</script>

<style scoped>

</style>
