<template>
	<div id="layoutError_content" class="bg-dark vh-100 text-light">
		<main>
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-6">
						<div class="text-center mt-4">
							<h1 class="display-1" v-if="code" v-html="code"></h1>
							<p class="lead" v-if="message" v-html="message"></p>
							<a v-if="link" href="#" @click="reloadPage">
								<i class="fas fa-redo me-1"></i>
								<span v-html="link"></span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: "Error",
	props:{
		code: {
			required: false,
			default: '500'
		},
		message: {
			required: false,
			default: 'Internal Server Error'
		},
		link: {
			required: false,
			default: 'Reload page'
		}
	},
	methods:{
		reloadPage: function (event) {
			event.preventDefault();
			location.reload();
		}
	}
}
</script>

<style scoped>

</style>
