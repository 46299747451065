<template>
	<div>
		<div class="position-row p-2">
			<div class="d-flex px-2" :style="{marginLeft: (BASE_MARGIN * depth) + 'px'}">
				<span class="me-auto mt-1">{{ position.name }}</span>

				<div class="btn-group" role="group" aria-label="Basic example">
					<button class="btn btn-outline-success btn-sm"
							@click="onClickAdd($event, position)"
					>
						<i class="fas fa-plus"></i>
					</button>
					<button class="btn btn-outline-primary btn-sm"
							@click="onClickEdit($event, position)"
					>
						<i class="fas fa-pencil-alt"></i>
					</button>
					<button class="btn btn-outline-danger btn-sm"
							:disabled="!canBeDeleted"
							@click="onClickDelete($event, position)"
					>
						<i class="fas fa-trash-alt"></i>
					</button>
				</div>
			</div>
		</div>

		<hr class="m-0">

		<PositionRow v-for="position in position.childrens"
					 :key="ENTITY_NAME + position.id"
					 :position="position"
					 :depth="depth + 1"
					 :on-click-add="onClickAdd"
					 :on-click-edit="onClickEdit"
					 :on-click-delete="onClickDelete"
		/>
	</div>
</template>

<script>
export default {
	name:  "PositionRow",
	props: {
		position:      {
			required: true
		},
		depth:         {
			required: false,
			default:  0
		},
		onClickAdd:    {
			required: true
		},
		onClickEdit:   {
			required: true
		},
		onClickDelete: {
			required: true
		}
	},
	data() {
		return {
			ENTITY_NAME: 'subposition',
			BASE_MARGIN: 40
		};
	},
	computed: {
		canBeDeleted: function () {
			return this.position.childrens.length <= 0 && this.position.canBeDeleted;
		}
	}
}
</script>

<style scoped lang="scss">
.position-row {
	&:hover {
		background-color: var(--bs-gray-200);
	}
}

.btn-outline-danger {
	&:disabled {
		color:        var(--bs-gray);
		border-color: var(--bs-gray);
	}
}
</style>
