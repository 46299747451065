<template>
	<div id="app">
		<div v-if="!loading && !appError && !maintenance">
			<div v-if="$store.getters.isAuthenticated">
				<Navbar/>

				<div id="layoutSidenav">
					<Sidebar/>

					<div id="layoutSidenav_content">
						<main>
							<div class="container-fluid px-4">
								<router-view/>
							</div>
						</main>

						<!--					<Footer/>-->
					</div>
				</div>
			</div>
			<router-view v-else/>
		</div>
		<BigLoader v-else-if="loading && !appError && !maintenance" :label="title + '<br>Avvio...'"/>
		<Error v-else-if="!loading && !appError && maintenance" code="503" message="Sito in manutanzione" link="Ricarica pagina"/>
		<Error v-else code="500" message="Errore generico" link="Ricarica pagina"/>
	</div>
</template>

<script>
import {title}   from "../site.config";
import BigLoader from "./components/BigLoader";
import Error     from "./components/Error";
import Navbar    from "./components/Navbar";
import Sidebar   from "./components/Sidebar";
import {doPing}  from "./utils/client";

const PING_TIMEOUT = 1500;
const MAX_PINGS    = 20;

export default {
	components: {Error, BigLoader, Sidebar, Navbar},
	data() {
		return {
			title: title
		};
	},
	computed: {
		loading:  function () {
			return this.$store.getters.isLoading;
		},
		maintenance:  function () {
			return this.$store.state.maintenance;
		},
		appError: function () {
			return this.$store.getters.isAppError;
		}
	},
	mounted() {
		this.manageSidebar();

		// ensure server in responding
		this.ping();
	},
	methods: {
		ping:          function (count = 0) {
			if (count <= MAX_PINGS) {
				doPing()
					.then(response => {
						setTimeout(() => {
							this.$store.commit('setMaintenance', !response.data.status);
							this.$store.dispatch('setLoaded');
						}, PING_TIMEOUT);
					})
					.catch(() => {
						setTimeout(() => {
							this.ping(count + 1);
						}, PING_TIMEOUT);
					});
			} else {
				this.$store.dispatch('setError');
			}
		},
		manageSidebar: function () {
			if (!this.$store.state.sidebarOpen) {
				document.body.classList.add('sb-sidenav-toggled');
			}
		}
	}
}
</script>

<style lang="scss">
@import "assets/scss/app";
</style>
