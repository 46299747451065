const serialize = obj => {
    const str = [];

    for (const p in obj) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }

    return str.join("&");
};

const unique = array => {
    return array.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
};

export {serialize, unique};
